* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.ant-popover-message-title {
  font-size: 16px;
}

.ant-modal-body {
  font-size: 16px;
}

.ant-message-error {
  font-size: 16px;
}
